var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('table',{staticClass:"portal-table"},[_vm._m(0),_c('tbody',_vm._l((_vm.companies),function(c){return _c('tr',{key:c.companyId,staticClass:"portal-table-row"},[_c('td',[_vm._v(_vm._s(c.companyName))]),_c('td',[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"me-3"},[_vm._v(_vm._s(_vm.selectTypeExpirationDate('generalLiabilityExpirationDate', c)))]),(c.generalLiabilityRequired)?_c('router-link',{staticClass:"insurance-aggregate__link",attrs:{"to":{
                  name: _vm.$routeNames.ARCHIVE_INSURANCE_GENERAL_LIABILITY,
                  params: _vm.getRouterParams(c.companyId),
                }}},[_c('font-awesome-icon',{attrs:{"icon":"arrow-up-right-from-square"}})],1):_vm._e()],1)]),_c('td',[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"me-3"},[_vm._v(_vm._s(_vm.selectTypeExpirationDate('professionalLiabilityExpirationDate', c)))]),(c.professionalLiabilityRequired)?_c('router-link',{staticClass:"insurance-aggregate__link",attrs:{"to":{
                    name: _vm.$routeNames.ARCHIVE_INSURANCE_PROFESSIONAL_LIABILITY,
                    params: _vm.getRouterParams(c.companyId),
                  }}},[_c('font-awesome-icon',{attrs:{"icon":"arrow-up-right-from-square"}})],1):_vm._e()],1)]),_c('td',[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"me-3"},[_vm._v(_vm._s(_vm.selectTypeExpirationDate('workersCompensationExpirationDate', c)))]),(c.workersCompensationRequired)?_c('router-link',{staticClass:"insurance-aggregate__link",attrs:{"to":{
                  name: _vm.$routeNames.ARCHIVE_INSURANCE_WORKERS_COMPENSATION,
                  params: _vm.getRouterParams(c.companyId),
                }}},[_c('font-awesome-icon',{attrs:{"icon":"arrow-up-right-from-square"}})],1):_vm._e()],1)]),_c('td',[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"me-3"},[_vm._v(_vm._s(_vm.selectTypeExpirationDate('disabilityExpirationDate', c)))]),(c.disabilityRequired)?_c('router-link',{staticClass:"insurance-aggregate__link",attrs:{"to":{
                  name: _vm.$routeNames.ARCHIVE_INSURANCE_DISABILITY,
                  params: _vm.getRouterParams(c.companyId),
                }}},[_c('font-awesome-icon',{attrs:{"icon":"arrow-up-right-from-square"}})],1):_vm._e()],1)]),_c('td',[_c('router-link',{staticClass:"insurance-aggregate__link",attrs:{"to":{
              name: _vm.$routeNames.ARCHIVE_INSURANCE_SETTINGS,
              params: _vm.getRouterParams(c.companyId),
              props: true,
            }}},[_c('font-awesome-icon',{attrs:{"icon":"gear"}})],1)],1)])}),0)]),(_vm.totalPages > 1)?_c('pagination-component',{key:_vm.pageNumber,attrs:{"page-number":_vm.pageNumber,"total-pages":_vm.totalPages,"on-change-page":function (page) { return _vm.changePage(page); }}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('th',[_vm._v("Company name")]),_c('th',[_vm._v("General liability expiration date")]),_c('th',[_vm._v("Professional liability expiration date")]),_c('th',[_vm._v("Workers compensation expiration date")]),_c('th',[_vm._v("Disability insurance expiration date")]),_c('th',[_vm._v("Settings")])])}]

export { render, staticRenderFns }