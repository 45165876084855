







































































































import { Vue, Component } from "vue-property-decorator";
import DocumentService from "@/services/DocumentService";
import { processError } from "@/utils/ComponentUtils";
import { namespace } from "vuex-class";
import InsuranceAggregateDTO from "@/dto/archive/insurance/InsuranceAggregateDTO";
import PaginationComponent from "@/components/util/PaginationComponent.vue";
import {WORKSPACE_ID, WORKSPACE_TYPE} from "@/constants/Paths";
import {WorkspaceTypePathElement} from "@/dto/auth/Workspace";
import { Dictionary } from "vue-router/types/router";

const AppModule = namespace("App");

@Component({
  components: { PaginationComponent }
})
export default class BusinessInsuranceAggregate extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  companies: Array<InsuranceAggregateDTO> | [] = [];
  private pageNumber = 1;
  private totalPages = 1;

  mounted(): void {
    this.loadInsuranceAggregate();
  }

  displayedExpirationDate(required: boolean | null, expirationDate: Date | null): string {
    if (required === true) {
      return expirationDate ? this.$dateUtils.formatDateToAmericanFormat(expirationDate) : 'Missing';
    }
    if (required === false) {
      return 'Not required';
    }
    return 'Not set';
  }

  selectTypeExpirationDate(typeExpirationDate: string, c: InsuranceAggregateDTO): string {
    switch (typeExpirationDate) {
      case 'generalLiabilityExpirationDate':
        return this.displayedExpirationDate(c.generalLiabilityRequired, c.generalLiabilityExpirationDate);
      case 'professionalLiabilityExpirationDate':
        return this.displayedExpirationDate(c.professionalLiabilityRequired, c.professionalLiabilityExpirationDate);
      case 'workersCompensationExpirationDate':
        return this.displayedExpirationDate(c.workersCompensationRequired, c.workersCompensationExpirationDate);
      case 'disabilityExpirationDate':
        return this.displayedExpirationDate(c.disabilityRequired, c.disabilityExpirationDate);
      default:
        return ''
    }
  }

  loadInsuranceAggregate(): void {
    this.startLoading();
    DocumentService.getInsuranceAggregate(this.pageNumber, 20).then(
      resp => {
        this.companies = resp.data.data;
        this.totalPages = resp.data.countOfPages;
        this.stopLoading();
      },
      error => {
        processError(error, this);
        this.stopLoading();
      }
    )
  }

  private changePage(page: number): void {
    this.pageNumber = page;
    this.loadInsuranceAggregate();
  }

  getRouterParams(companyId: number | string): Dictionary<string | number> {
    const params: Dictionary<string | number> = {};
    params[WORKSPACE_TYPE] = WorkspaceTypePathElement.COMPANY;
    params[WORKSPACE_ID] = companyId;
    return params;
  }

}
